//redux store configuration

//importing necessary modules
import { configureStore } from '@reduxjs/toolkit';
import imageReducer from './imageSlice';
import favouriteReducer from './favouriteSlice';

//creating store
const store = configureStore({
    reducer: {
        images: imageReducer,
        favourites: favouriteReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;