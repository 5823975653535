// src/components/Gallery.tsx

// fi x up to send reg image to favourites so can open on modal
// also button and search position a little off
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchImages, setPage } from '../redux/imageSlice';
import { addFavourite, removeFavourite } from '../redux/favouriteSlice';
import { RootState, AppDispatch } from '../redux/store';
import { FaArrowLeft, FaArrowRight, FaHeart, FaRegHeart } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Modal from './Modal';
import './Gallery.css';

// functional component to display image gallery
const Gallery: React.FC = () => {
    // State to manage search input
    const [searchInput, setSearchInput] = useState('');
    // State to manage search term
    const [searchTerm, setSearchTerm] = useState('indigo');
    // State to manage image in the modal
    const [selectedImage, setSelectedImage] = useState<{
        url: string;
        altDescription: string;
        user: {
            name: string;
            profileImage: string;
            username: string;
            links: {
                html: string;
            };
        };
    } | null>(null);
    // Set up the redux function with typescript support
    const dispatch = useDispatch<AppDispatch>();
    // Select images from Redux store
    const images = useSelector((state: RootState) => state.images.images);
    // Select status, error, page, and totalPages from Redux store
    const status = useSelector((state: RootState) => state.images.status);
    const error = useSelector((state: RootState) => state.images.error);
    const page = useSelector((state: RootState) => state.images.page);
    const totalPages = useSelector((state: RootState) => state.images.totalPages);
    // Select favourites from Redux store
    const favourites = useSelector((state: RootState) => state.favourites.favourites);

    // Fetch images from Unsplash API
    useEffect(() => {
        dispatch(fetchImages({ search: searchTerm, page }));
    }, [dispatch, searchTerm, page]);
    // Handle search
    const handleSearch = () => {
        dispatch(setPage(1));
        setSearchTerm(searchInput);
    };
    // Handle pagination
    const handleNextPage = () => {
        if (page < totalPages) {
            dispatch(setPage(page + 1));
        }
    };
    // Handle pagination
    const handlePrevPage = () => {
        if (page > 1) {
            dispatch(setPage(page - 1));
        }
    };
    // Open modal
    const openModal = (imageUrl: string, altDescription: string, user: { name: string; profileImage: string; username: string; links: { html: string } }) => {
        setSelectedImage({ url: imageUrl, altDescription, user });
    };
    // Close modal
    const closeModal = () => {
        setSelectedImage(null);
    };
    // check image is favourite or not
    const isFavorite = (id: string) => favourites.some(fav => fav.id === id);
    // Toggle favourite status of an image
    const toggleFavourite = (image: { id: string; urls: { small: string }; alt_description: string; user: { name: string; profile_image: { small: string }; username: string; links: { html: string } } }) => {
        if (isFavorite(image.id)) {
            dispatch(removeFavourite(image.id));
        } else {
            dispatch(addFavourite(image));
        }
    };
    // Display image gallery
    return (
        <div className="gallery-container">
            <div className="heading">Unsplash Image Gallery</div>
            
            <div className="search">
                <input
                    type="text"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    placeholder="Search Images"
                />
            
                <button onClick={handleSearch}>Fetch Images</button>

                <Link
                    to="/favourites"
                    className={`navigation ${favourites.length === 0 ? 'disabled' : ''}`}
                >
                    <FaHeart />
                </Link>
            </div>
            {status === 'failed' && <p>{error}</p>}

            <div className="image-gallery">
                {images.map((image) => (
                    <div key={image.id} className="image-container">
                        <img
                            src={image.urls.small}
                            alt={image.alt_description}
                            onClick={() => openModal(image.urls.regular, image.alt_description, {
                                name: image.user.name,
                                profileImage: image.user.profile_image.small,
                                username: image.user.username,
                                links: image.user.links
                            })}
                        />
                        <div className="overlay">
                            <button
                                className="favourite-button"
                                onClick={() => toggleFavourite(image)}
                            >
                                {isFavorite(image.id) ? <FaHeart /> : <FaRegHeart />}
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            <div className="pagination">
                <button
                    onClick={handlePrevPage}
                    disabled={page === 1}
                    className={page === 1 ? 'disabled' : ''}
                >
                    <FaArrowLeft />
                </button>
                <span className='page-num'>
                    {page} of {totalPages}
                </span>
                <button
                    onClick={handleNextPage}
                    disabled={page === totalPages}
                    className={page === totalPages ? 'disabled' : ''}
                >
                    <FaArrowRight />
                </button>
            </div>

            <Modal
                show={selectedImage !== null}
                onClose={closeModal}
                imageUrl={selectedImage ? selectedImage.url : ''}
                altDescription={selectedImage ? selectedImage.altDescription : ''}
                user={selectedImage ? selectedImage.user : null}
            />
        </div>
    );
};

export default Gallery;
