//src/components/Modal.tsx

import React from 'react';
import './Modal.css';
// define the props interface
interface ModalProps {
    // show modal boolean
    show: boolean;
    // function to close the modal
    onClose: () => void;
    
    imageUrl: string;
    altDescription: string;
    // user object
    user: {
        name: string;
        profileImage: string;
        username: string;
        links: {
            html: string;
        };
    
    } | null;
}
// functional component to display the modal
const Modal: React.FC<ModalProps> = ({ show, onClose, imageUrl, altDescription, user }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <span className="modal-close" onClick={onClose}>&times;</span>
                <div className="modal-border">
                    <img src={imageUrl} alt={altDescription} className="modal-image" />
                    {user && (
                        <div className="modal-user">
                            <div className="user-info">
                                <div className="user-profile">  
                                    <img src={user.profileImage} alt={user.name} className="user-image" />
                                    <div> @{user.username}</div>
                                </div>
                                
                                <div><strong>{user.name} </strong></div>
                                <div><a href={user.links.html} target="_blank" rel="noopener noreferrer">Link to Profile and other Images</a></div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};



export default Modal;
