//src/redux/imageSlice.ts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the User interface
interface User {
  name: string;
  profile_image: {
    small: string;
    medium: string;
    large: string;
  };
  username: string;
  links: {
    html: string;
  };
}
//  Define the Image interface
interface Image {
  id: string;
  urls: {
    small: string;
    regular: string; 
    full: string;    
  };
  alt_description: string;
  user: User; 
}

// Define the ImageState interface
interface ImageState {
  images: Image[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  page: number;
  totalPages: number;
}
// Define the initial state
const initialState: ImageState = {
  images: [],
  status: 'idle',
  error: null,
  page: 1,
  totalPages: 0,
};

// Fetch images from Unsplash API using Fetch API
export const fetchImages = createAsyncThunk(
  'images/fetchImages',
  async ({ search, page }: { search: string; page: number }) => {
    const response = await fetch(
      `https://api.unsplash.com/search/photos?query=${search}&page=${page}&per_page=15&client_id=YOXnkl64Vq8XUTQ28pQZoUgi2YnIDukfj02341WZsqI`
    );
    if (!response.ok) {
      throw new Error('Failed to fetch images');
    }
    const data = await response.json();
    return { results: data.results, totalPages: data.total_pages };
  }
);
// Create the image slice
const imageSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    // Set the page number
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
  // Add extra reducers for the fetchImages action
  extraReducers: (builder) => {
    builder
      .addCase(fetchImages.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchImages.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.images = action.payload.results;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchImages.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch images';
      });
  },
});
// Export the actions
export const { setPage } = imageSlice.actions;
export default imageSlice.reducer;
