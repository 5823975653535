// src/components/Favourites.tsx



import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../redux/store';
import { addFavourite, removeFavourite } from '../redux/favouriteSlice';
import { FaHeart, FaRegHeart, FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Modal from './Modal';
import './Gallery.css'; 

// functional component to display favourite images
const Favourites: React.FC = () => {
    // Select Favourites from Redux store
    const favourites = useSelector((state: RootState) => state.favourites.favourites);
    // State to manage image in the modal
    const [selectedImage, setSelectedImage] = useState<{
        url: string;
        altDescription: string;
        user: {
            name: string;
            profileImage: string;
            username: string;
            links: {
                html: string;
            };
        };
    } | null>(null);
    // Set up the redux function with typescript support
    const dispatch = useDispatch<AppDispatch>();

    // Open modal
    const openModal = (imageUrl: string, altDescription: string, user: { name: string; profileImage: string; username: string; links: { html: string } }) => {
        setSelectedImage({ url: imageUrl, altDescription, user });
    };

    // Close modal
    const closeModal = () => {
        setSelectedImage(null);
    };
    // check image is favourite or not
    const isFavourite = (id: string) => favourites.some(fav => fav.id === id);

    // Toggle favourite status of an image
    const toggleFavourite = (image: { id: string; urls: { small: string }; alt_description: string; user: { name: string; profile_image: { small: string }; username: string; links: { html: string } } }) => {
        if (isFavourite(image.id)) {
            dispatch(removeFavourite(image.id));
        } else {
            dispatch(addFavourite(image));
        }
    };
    // Display favourite images
    return (
        <div className="gallery-container">
            <div className="heading">Favourite Images</div>
            <div className="navigation">
                <Link to="/" className="navigation"><span className="icon"><FaArrowLeft /></span> Gallery</Link>
            </div>
            {favourites.length === 0 && <p>No favourite images</p>}
            <div className="image-gallery-favourites">
                {favourites.map((image) => (
                    <div key={image.id} className="image-container">
                        <img
                            src={image.urls.small}
                            alt={image.alt_description}
                            onClick={() => openModal(image.urls.small, image.alt_description, {
                                name: image.user.name,
                                profileImage: image.user.profile_image.small,
                                username: image.user.username,
                                links: image.user.links
                            })}
                        />
                        <div className="overlay">
                            <button
                                className="favourite-button"
                                onClick={() => toggleFavourite(image)}
                            >
                                {isFavourite(image.id) ? <FaHeart /> : <FaRegHeart />}
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            <Modal
                show={selectedImage !== null}
                onClose={closeModal}
                imageUrl={selectedImage ? selectedImage.url : ''}
                altDescription={selectedImage ? selectedImage.altDescription : ''}
                user={selectedImage ? selectedImage.user : null}
            />
        </div>
    );
};

export default Favourites;
