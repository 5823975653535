
//src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Gallery from './components/Gallery';
import Favourites from './components/Favourites';
import './App.css';
// Define the App component with basic routing
const App: React.FC = () => {
    return (
        <Router>
            <div className="app-container">
                <Routes>
                    <Route path="/" element={<Gallery />} />
                    <Route path="/favourites" element={<Favourites />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
